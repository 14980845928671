import { defineStore  } from 'pinia'
import { accountService } from '@/services/service.account';
//import { showToast } from '@/components/Notification';
import router from '@/router';
import _ from 'lodash';
import ShareFunc from "@/helpers/ShareFunc";
import * as Account from '@/models/model.account';
import { customJoi as Joi, customJoi, prepareJoiErrorArr } from '@/models/customJoi';
import jwt_decode from "jwt-decode";
import Cookies from 'js-cookie';
import Constant from "@/helpers/Constant";
import { useStoreCompany } from '@/stores/store.company'
import { useStoreSite } from '@/stores/store.site';

// user data from login api
const objUser = { userId:null, firstName:null, lastName:null, mobileFull:null, idToken:null, accessToken:null, refreshToken:null, };
const obj = { user: _.cloneDeep(objUser)};

export const useStoreAccount = defineStore('account', {
  state: () => (obj),
  //state: () => ({user:{firstName: "", lastName: "", salutations:"", newUser:true}}),
  getters: {
    isLoggedIn: (state) => state.user?.userId != null,
  },
  actions: {
    reset() {
      this.user = _.cloneDeep(objUser);
    },
    oidcLogin(data) {
      //console.log("oidcLogin", data)
      //ShareFunc.track("account-sign-in-auth-code", "account", "sign-in");
      return accountService.oidcLogin(data)
        .then((res) => {
          //console.log("loginOidc", res);
          this._afterLogin(res.data);
          return res;
        })
        .catch((err) => {
          console.log('oidcLoginFailure: ', err);
          throw err;
        });
    },
    previewLogin(token) {
      //console.log("oidcLogin", data)
      //ShareFunc.track("account-sign-in-auth-code", "account", "sign-in");
      return accountService
        .previewLogin(token)
        .then((res) => {
          // console.log("loginPreview", res);
          this._afterLogin(res.data);
          return res;
        })
        .catch((err) => {
          console.log("oidcLoginFailure: ", err);
          throw err;
        });
    },
    logout() {
      //ShareFunc.track("account-logout", "account", "logout");
      this.reset();
      return accountService.logout()
        .then((res) => {
          localStorage.setItem(Constant.STORAGE_WAS_LOGGED_IN, 'false');
        }).catch((err) => {
          console.log('logout err', err);
        });
    },
    resumeLogin() {
      // console.log("resumeLogin");
      //ShareFunc.track("account-resume-login", "account", "resume-login");
      return accountService.resumeLogin()
        .then((res) => {
          const data = res.data;
          this._afterLogin(data);
          return res;
        })
        .catch((err) => {
          console.log(err);
          this.error = err
          return err;
        });
    },
    refreshToken() {
      // loynote: refresh token via cookie's refreshtoken withCredential = true;
      return accountService.refreshToken()
        .then((res) => {
          const data = res.data;
          this._afterLogin(data);
          //return true;
          return res;
        })
        .catch((err) => {
          // console.log("refreshTokenFailure: ", err);
          // return false;
          return err;
        });
    },
    async _afterLogin(data) {
      const decoded = jwt_decode(data.accessToken);
      const decoded2 = jwt_decode(data.idToken);
      //
      // console.log('data', data);
      // console.log('accesstoken', decoded);
      // console.log("idtoken", decoded2)

      this.user.userId = decoded.sub;
      this.user.mobileFull = decoded2.mobileFull;
      this.user.salutation = decoded.salutation;
      this.user.firstName = decoded2.firstName;
      this.user.lastName = decoded2.lastName;
      this.user.fullName = decoded2.fullName;
      this.user.displayPicture = decoded2.displayPicture;
      this.user.email = decoded2.email;
      this.user.idToken = data.idToken;
      this.user.accessToken = data.accessToken;
      this.user.mobileNumber = decoded2.mobile;
      this.user.mobileCountryCode = decoded2.mobileCountryCode;
      this.user.skipCheck = decoded2.skipCheck


      // console.log("_afterLogin", this.user);
      // Cookies.set(Constant.COOKIE_WAS_LOGGED_IN, 'true');
      if (!this.user.skipCheck) localStorage.setItem(Constant.STORAGE_WAS_LOGGED_IN, 'true');
      //Cookies.set(Constant.COOKIE_REFRESH_TOKEN, data.refreshToken);
      // console.log("user",this.user.data);
      const storeCompany = useStoreCompany();
      const res = await storeCompany.getAllCompany()
      const companies = res.data;

      let c = companies[companies.length -1]
      if (decoded2.previewCompanyId) {
        const res = await storeCompany.getSingleCompany(decoded2.previewCompanyId);
        storeCompany.companies = [res.data]
        c = res.data;
      } else if (storeCompany.preferedSelectCompanyId) {
        c = _.find(companies, { id: storeCompany.preferedSelectCompanyId });

        //if not found
        if(!c) c = companies[companies.length-1]
      } 

      //this line buggy
      await storeCompany.switchCompany(c);
    },



    async updateProfile (data) {
      // console.log("updateProfile", data)
      // if (data.displayPicture.key == "") {
      //   delete data.displayPicture;
      // }
      return accountService.updateProfile(data)
        .then((res) => {
          return true;
        })
        .catch((err) => {
          throw err;
        });
    }



  },
});







/*
   
storeAccount.$subscribe((mutation, state) => {
  console.log("storeAccount", mutation);
  mutation.type 
  mutation.storeId
  mutation.payload
  
})
    */
